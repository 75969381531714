import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Cta from "../components/cta"
import Seo from "../components/seo"

const PortiPage = () => (
    <Layout>
        <Seo 
            title="Portfolio" 
            description="View Launch Lab's web design and web development portolio including startups, websites, chatbots and apps we have designed and developed."pathname="/portfolio/"
        />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      >Our work</h1>
                      <motion.h2 className="hero-lead"
                        initial="hidden"
                        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                        transition={{ ease: "easeOut", duration: 0.5 }}
                      >
                          View some of the startups, websites, applications and other projects we've worked on
                      </motion.h2>
                      <div className="btn-row">
                        <motion.div 
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                            transition={{ ease: "easeOut", duration: 0.5 }}
                        > 
                            <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                                <span className="flex flex-ac down">
                                <ArrowRight />
                                </span>
                            </button>
                        </motion.div>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <motion.div 
                    className="row porti-row"
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0] }} 
                    transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                >
                    <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                        <div className="porti-item-img bg-noise giraffe">
                            <StaticImage 
                                src="../images/giraffe.png"
                                alt="A collage of the Giraffe website screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Giraffe</h3>
                            <p>We designed and developed a new Webflow website for this Australian startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/wordpress-to-webflow-case-study/" className="porti-item">
                        <div className="porti-item-img bg-noise apos">
                            <StaticImage 
                                src="../images/apositive.png"
                                alt="A collage of the APositive website screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>APositive</h3>
                            <p>We designed and developed a new website on Webflow for this Australian Fintech company.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/game-studio-website/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/bzt.jpg"
                                    alt="Bazooka Tango homepage screen grab"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Bazooka Tango</h3>
                                <p>We designed and developed a new website for this US-based game studio.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </Link>

                    <Link to="/webflow-website-case-study/" className="porti-item">
                        <div className="porti-item-img bg-noise immu">
                            <StaticImage 
                                src="../images/immutable-site.png"
                                alt="A collage of the Immutable website screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Immutable</h3>
                            <p>We developed the Immutable website on Webflow Enterprise and provide ongoing support.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/feezy-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/feezy-website.png"
                                alt="Feezy website home page"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Feezy</h3>
                            <p>We designed and developed a 2-sided marketplace and a marketing website for this Australia-based startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <a href="https://www.dabble.com.au/" target="_blank" rel="noopener noreferrer" title="View the Dabble website" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/dabble.png"
                                    alt="Emoji chat image from the Dabble website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Dabble</h3>
                                <p>We designed and developed a content driven Gatsby website with headless CMS for Dabble.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                    </a>

                    <a href="https://www.advoc8.co/" target="_blank" rel="noopener noreferrer" title="View the Advoc8 website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/advoc8-website.png"
                                    alt="Advoc8 website home page"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Advoc8</h3>
                                <p>We designed and developed a Webflow website for this Australian startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                    </a>

                    <a href="https://www.digitalworldsnfts.com/" target="_blank" rel="noopener noreferrer" title="View the Digital Worlds NFTs website" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/gods-unchained.png"
                                    alt="Gods Unchained web3 game"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Digital Worlds NFTs</h3>
                                <p>Launch Lab developed a Webflow website for this non-profit organisation.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </a>

                    <Link to="/enabli-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/enabli-screens.png"
                                alt="A collage of the Enabli website and application screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Enabli</h3>
                            <p>We designed and developed HR Tech software and a marketing website for this Sydney-based startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/webflow-portfolio/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/webflow-logo.png"
                                alt="Webflow logo"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Webflow Websites</h3>
                            <p>We've been working in Webflow a lot lately. View some of the Webflow websites we've recently completed.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/waddle-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/waddle-xero.jpg"
                                alt="Photo of Waddle, a fintech startup, on stage at Xerocon"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Waddle, a Xero company</h3>
                            <p>We developed a Gatsby website with a headless CMS for Waddle, a fintech startup acquired by Xero.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/gses-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/gses-screen.png"
                                alt="The login screen for the GSES solar analytics web application"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>GSES</h3>
                            <p>We designed and developed a solar enery monitoring and analytics web app for Global Sustainable Energy Solutions.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/madclarity-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/madclarity-screens.png"
                                alt="screens of the Madclarity website design and illustrations completed by Launch Lab"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Madclarity</h3>
                            <p>We designed and developed a website and completed all website illustrations for this media agency.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/fixitdoc-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/fixitdoc-screens.png"
                                alt="FixitDoc mobile aplication"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>FixitDoc</h3>
                            <p>We designed and developed a startup mobile app, website and content management system.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                                <div className="work-pill mobileapp">Mobile App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/startup-case-study-fintech/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/deferit-image.png"
                                alt="Image of lady smiling and other design assets from Deferit, a fintech startup in Australia"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Deferit</h3>
                            <p>We developed a web and mobile app for Deferit, a fintech startup in Australia.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill mobileapp">Mobile App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/gbca-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/gbca-screens.png"
                                alt="Australia National University logo"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Green Building Council of Australia</h3>
                            <p>We do web design and Django web development work for this large Australian nonprofit.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/startup-case-study-halogen-health/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/halogen-health-screen.png"
                                alt="Homepage of the Halogen Health website"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Halogen Health</h3>
                            <p>We designed and developed a website, CMS and web app for this Sydney-based healthtech startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/case-study-react-development-health-tech/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/proasis.png"
                                alt="a molecule structure"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Proasis</h3>
                            <p>We help Proasis with frontend development (React), infrastructure and machine learning.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill ml">Machine Learning</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/pt-blink-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/pt-blink-screen.png"
                                alt="Homepage of the PT Blink website"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>PT Blink</h3>
                            <p>We designed and developed a website and headless CMS for this proptech startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/startup-case-study-student-marketplace/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/studentguru-screen.png"
                                alt="Homepage of the Studentguru website"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Studentguru</h3>
                            <p>We designed and developed a website and web application for this student marketplace.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/case-study-anu-scheduling-software/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/anu.png"
                                alt="Australia National University logo"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Australia National University</h3>
                            <p>We developed an analytics and scheduling platform for The Department of Nuclear Physics, Research School of Physics and Engineering at ANU.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/joycards-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/joycards.png"
                                alt="Joycards homepage"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Joycards</h3>
                            <p>We designed and developed the Gatsby website and Vue.js web application for this popular B2C startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill mobileapp">Mobile App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>

                </motion.div>
            </div>
        </section>

        <section className="bg-purple section-pad-sm white">
            <div className="container">
                <div className="row long-text">
                    <p>The projects above are a small sample of our work. Over the years we've developed websites, blogs and landing pages for a large financial institution, developed a chatbot for City of Sydney, designed and developed charity websites, developed an Edtech startup, developed 2-sided marketplaces, multiple recruitment platforms and many websites and CMS'.</p>
                </div>
            </div>
        </section>


        <Cta 
            text="Get a web design quote and / or a web development quote from a local onshore Australian design & development team"
        />
    </Layout>
)

export default PortiPage